import {mapActions, mapGetters, mapMutations} from "vuex";
import {minLength, maxLength, required, email} from "vuelidate/lib/validators";

export default {
  name: "main-footer",
  data() {
    return {
      width: window.innerWidth,
      email: '',
      description: '',
      errorEmail: false,
      validationErrors: {},
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    description: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(500)
    }
  },
  created() {
    this.getContacts()
  },
  watch: {
    '$route': function () {
      this.$v.$reset();
    },
  },
  computed: {
    ...mapGetters({
      contacts: 'setting/contacts'
    }),

    emailErrors() {
      let error = [];
      if (!this.$v.email.$dirty) {
        return error;
      }
      if (!this.$v.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    descriptionErrors() {
      let error = [];
      if (!this.$v.description.$dirty) {
        return error;
      }
      if (!this.$v.description.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.description.minLength) {
        error.push(this.$t('validationMin') + ' 10');
      }
      if (!this.$v.description.maxLength) {
        error.push(this.$t('validationMax') + ' 500');
      }
      if (this.validationErrors.description) {
        this.validationErrors.description.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendFeedback: 'setting/SEND_FEEDBACK',
      getContacts: 'setting/GET_CONTACTS'
    }),
    ...mapMutations({
      showThanksPopup: 'setting/SHOW_THANKS_POPUP'
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendFeedback({email: this.email, description: this.description}).then(() => {
          this.$v.$reset();
          this.email = '';
          this.description = ''
          this.showThanksPopup(true)
        })
      }
    }
  }
}
